import React from 'react';
import { Link } from 'gatsby';
import Background from './Image';
import styles from './styles.module.css';

export default function() {
  return (
    <Background className={styles.container}>
      <div className={styles.inner}>
        <h2 className={styles.title}>
          Извините, такой страницы не существует!
        </h2>
        <Link className={styles.link} to={'/'}>
          Вернуться на главную
        </Link>
      </div>
    </Background>
  );
}
